import { Grid } from "@boxt/design-system";

import type { PageType } from "@Components/FinanceCalculator/types";
import type { ButterFinanceTextTabFields } from "@Components/pages/finance/types";

import { LeftColumnDiv, RightColumnDiv } from "../../styles";

import LeftColumnContent from "./components/LeftColumnContent";
import RightColumnContent from "./components/RightColumnContent";

export type Props = {
  content: ButterFinanceTextTabFields;
  onAddToBasket?: () => void;
  onProductDetailsClick?: () => void;
  pageType?: PageType;
};

const TextTab = ({ content, onAddToBasket, onProductDetailsClick, pageType = "finance" }: Props) => {
  return (
    <>
      <Grid.Col lg={9} as={LeftColumnDiv}>
        <LeftColumnContent content={content} />
      </Grid.Col>
      <Grid.Col lg={7} as={RightColumnDiv}>
        <RightColumnContent
          content={content}
          onAddToBasket={onAddToBasket}
          onProductDetailsClick={onProductDetailsClick}
          pageType={pageType}
        />
      </Grid.Col>
    </>
  );
};

export default TextTab;
