import { capitalize } from "lodash-es";

type Data = {
  question?: string;
  questionSuffix: string | null;
  template: string | null;
};

const getQuestionLocalePath = ({ question, questionSuffix, template }: Data) => {
  const questionKey = questionSuffix ? `${question}${capitalize(questionSuffix)}` : question;
  return template ? `${questionKey}.${template}` : questionKey;
};

export default getQuestionLocalePath;
