// since there are some questions that basically duplicated like howCloseFlueIsToGround & howCloseWillFlueBeToGround,
// which are the same question but timing is different, timeSignature been added to handle this case and avoid
// having duplicated questions in screener context

const formatQuestion = (
  question: string | Record<string, string> | null,
): { key: string; timeSignature?: string } | null => {
  if (!question) return null;

  if (typeof question === "string") {
    return { key: question };
  }

  const [key, value] = Object.entries<string>(question)[0];

  if (["is", "will"].includes(value)) return { key, timeSignature: value };

  return { key: `${key}.${value}` };
};

export default formatQuestion;
