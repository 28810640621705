const parseDigits = (string: string) => (string.match(/\d+/g) || []).join("");
const parseFinanceDigits = (string: string) => (string.match(/[\d.]+/g) || []).join("");

const formatDate = (string: string, separator: string) => {
  const digits = parseDigits(string);
  const chars = digits.split("");
  return chars
    .reduce(
      (prevValue, currentValue, index) =>
        index === 2 || index === 4 ? `${prevValue}${separator}${currentValue}` : `${prevValue}${currentValue}`,
      "",
    )
    .substring(0, 10);
};

const formatDateWithAppend = (value: string, separator: string = "/") => {
  const formattedValue = formatDate(value, separator);

  if (value.endsWith(separator)) {
    if (formattedValue.length === 2) {
      return `${formattedValue}${separator}`;
    }

    if (formattedValue.length === 5) {
      return `${formattedValue}${separator}`;
    }
  }

  return formattedValue;
};

const appendDateSeparator = (value: string, separator: string = "/") =>
  value.length === 2 || value.length === 5 ? `${value}${separator}` : value;

const sortCodeFormat = (value: string) => {
  const digits = parseDigits(value).substring(0, 8);

  const chars = digits.split("");
  return chars
    .reduce(
      (prevValue, currentValue, index) =>
        index === 2 || index === 4 ? `${prevValue}-${currentValue}` : `${prevValue}${currentValue}`,
      "",
    )
    .substring(0, 8);
};

const currencyFormat = (value: string) => {
  const digits = parseDigits(value);
  const number = parseInt(digits, 10);
  if (Number.isNaN(number)) {
    return "";
  }

  return number.toLocaleString("en", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
  });
};

const currencyFormatWithPence = (value: string) => {
  const digits = parseFinanceDigits(value);
  const number = parseFloat(digits);

  if (Number.isNaN(number)) {
    return "";
  }

  const hasDecimals = /\.\d+/.test(digits);

  return number.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: 2,
  });
};

const convertStringToNumber = (value: string) => parseInt(value.replace(/[^0-9]/g, ""), 10);

export {
  appendDateSeparator,
  convertStringToNumber,
  currencyFormat,
  formatDateWithAppend,
  parseDigits,
  sortCodeFormat,
  parseFinanceDigits,
  currencyFormatWithPence,
};
