import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, H3, Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter from "@Helpers/currency";
import { BOXT_CUSTOMER_URLS } from "@Collections/routes";
import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import type { ButterFinanceCalculatorTabFields } from "@Components/pages/finance/types";

import { RightColumnContainer, TextWrapper } from "./styles";

export type Props = {
  activePlan: FinancePlan;
  content: ButterFinanceCalculatorTabFields;
  hasFixedAmount: boolean;
  onAddToBasket?: () => void;
  onProductDetailsClick?: () => void;
  isBnpl: boolean;
  isPdpRedirectEnabled: boolean;
  showAddToBasketCta: boolean;
  ctaText: string;
};

const RightColumnContent = ({
  content,
  activePlan,
  hasFixedAmount,
  onAddToBasket,
  onProductDetailsClick,
  isBnpl,
  isPdpRedirectEnabled,
  showAddToBasketCta,
  ctaText,
}: Props) => {
  const { t } = useTranslation("common");

  const {
    calc_tab_cta_button_link: ctaButtonLink,
    calc_tab_cta_button_text: ctaButtonText,
    calc_tab_cta_button_theme: ctaButtonTheme,
  } = content;

  return (
    <RightColumnContainer>
      <TextWrapper>
        <Paragraph boxtTheme="slate">
          <Trans
            i18nKey={
              isBnpl ? "common:calculator.deferred-monthly-payments-of" : "common:calculator.monthly-payments-of"
            }
            values={{ months: activePlan.term, deferralPeriod: activePlan.deferredPeriod }}
            components={{ strong: <strong /> }}
          />
        </Paragraph>
        <H3 boxtTheme="coalDark" weight="bold">
          {currencyFormatter(activePlan.calculations.monthlyPayment)}
        </H3>
      </TextWrapper>
      <Spacing mb={2}>
        {hasFixedAmount ? (
          <>
            {showAddToBasketCta && (
              <Spacing mv={2}>
                <Button boxtTheme="jade" isFullWidth size="large" onClick={onAddToBasket}>
                  {t("calculator.add-to-basket")}
                </Button>
              </Spacing>
            )}
            {onProductDetailsClick && (
              <Button
                boxtTheme={isPdpRedirectEnabled ? "jade" : "coal"}
                isFullWidth
                size="large"
                skin={isPdpRedirectEnabled ? "primary" : "secondary"}
                onClick={onProductDetailsClick}
              >
                {ctaText}
              </Button>
            )}
          </>
        ) : (
          <Link href={ctaButtonLink || BOXT_CUSTOMER_URLS.BOILER} passHref legacyBehavior>
            <Button boxtTheme={ctaButtonTheme?.theme || "jade"} isFullWidth size="large">
              {ctaButtonText || t("calculator.fixed-price")}
            </Button>
          </Link>
        )}
      </Spacing>
    </RightColumnContainer>
  );
};

export default RightColumnContent;
