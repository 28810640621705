import { dangerouslySetInnerHTML } from "@Helpers/dangerouslySetInnerHTML";
import type { FONT_COLOR } from "@Collections/themeColors";
import { Container } from "@Components/pages/common/ButterHTMLParsingContent/styles";
import type { ButterHTMLParsingFields } from "@Components/pages/common/ButterHTMLParsingContent/types";
import type { IHTMLParserComponentMapConfig } from "@Components/pages/common/HTMLParser/config";
import { getDOMParserOptions } from "@Components/pages/common/HTMLParser/helpers";

type Props = {
  addContainerSpacing?: boolean;
  fields: ButterHTMLParsingFields;
  componentMap?: IHTMLParserComponentMapConfig;
  contentTheme?: keyof typeof FONT_COLOR;
  idAttribute: string;
};

const ButterHTMLParsingContent = ({
  addContainerSpacing = true,
  contentTheme = "coalLighter",
  fields,
  componentMap,
  idAttribute,
}: Props) => (
  <Container $addContainerSpacing={addContainerSpacing} id={idAttribute}>
    {dangerouslySetInnerHTML(fields.content, getDOMParserOptions({ contentTheme, componentMap }))}
  </Container>
);

export default ButterHTMLParsingContent;
