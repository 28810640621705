import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { useFsFlag } from "@flagship.io/react-sdk";
import { isEmpty } from "lodash-es";
import { Grid, LoadingOverlay } from "@boxt/design-system";

import { Products } from "@Collections/products";
import { LeftColumnDiv, RightColumnDiv } from "@Components/pages/finance/styles";
import type { ButterFinanceCalculatorTabFields } from "@Components/pages/finance/types";
import useFinance from "@Hooks/useFinance";

import LeftColumnContent from "./components/LeftColumnContent";
import PaymentDetails from "./components/PaymentDetails";
import RightColumnContent from "./components/RightColumnContent";
import { DEFAULT_MIN_PURCHASE_PRICE, DEFAULT_PURCHASE_PRICE, isValidPrice } from "./helpers";
import { CalculatingContainer, ContentWrapper, LoadingWrapper } from "./styles";
import { FinancePaymentTypes, type PageType } from "./types";

type Props = {
  content: ButterFinanceCalculatorTabFields;
  fixedAmount?: number;
  onAddToBasket?: () => void;
  zeroPercentFinanceThirtySix?: boolean;
  zeroPercentFinanceFortyEight?: boolean;
  includeScrollDelay?: boolean;
  onProductDetailsClick?: () => void;
  isBnpl?: boolean;
  isPayMonthly?: boolean;
  pageType?: PageType;
};

const FinanceCalculator = ({
  content,
  fixedAmount,
  onAddToBasket,
  onProductDetailsClick,
  includeScrollDelay = false,
  zeroPercentFinanceThirtySix = true,
  zeroPercentFinanceFortyEight = true,
  isBnpl = false,
  isPayMonthly = true,
  pageType = "finance",
}: Props) => {
  const { t } = useTranslation("common");
  const { calc_tab_left_column_default_value_displayed, calc_tab_left_column_minimum_value_allowed, product_type } =
    content;
  const defaultPurchasePrice = calc_tab_left_column_default_value_displayed || DEFAULT_PURCHASE_PRICE;
  const minPurchasePrice = calc_tab_left_column_minimum_value_allowed || DEFAULT_MIN_PURCHASE_PRICE;
  const productType = isEmpty(product_type) ? Products.Boiler : (product_type?.product_type as Products);

  const startingPrice = fixedAmount || defaultPurchasePrice;

  const [purchasePrice, setPurchasePrice] = useState(startingPrice);
  const [priceOfProductInputValue, setPriceOfProductInputValue] = useState(startingPrice);
  const [selectedPlanId, setSelectedPlanId] = useState<string>();
  const [depositAmount, setDepositAmount] = useState(startingPrice / 2);

  const { activePlan, financePlans, isCalculating } = useFinance({
    deposit: Math.floor(depositAmount),
    isBnpl,
    isPayMonthly,
    productType,
    purchasePrice,
    selectedPlanId,
    zeroPercentFinanceThirtySix,
    zeroPercentFinanceFortyEight,
  });

  const isPdpRedirectEnabled = useFsFlag("pdp-is-mandatory-step-v2", false).getValue();

  const showAddToBasketCta =
    (onAddToBasket && pageType === "pdp") || (onAddToBasket && pageType === "plp" && !isPdpRedirectEnabled);

  const ctaText =
    isPdpRedirectEnabled && pageType !== "finance" ? t("calculator.choose") : t("calculator.view-product-detail");

  const handleOnPriceChange = (value: number) => {
    setPriceOfProductInputValue(value);

    if (isValidPrice(minPurchasePrice, value)) {
      setDepositAmount(value * (depositAmount / purchasePrice));
      setPurchasePrice(value);
    }
  };

  const handleOnPriceBlur = (value: number) => {
    if (!isValidPrice(minPurchasePrice, value)) {
      setDepositAmount(minPurchasePrice * (depositAmount / purchasePrice));
      setPurchasePrice(minPurchasePrice);
      setPriceOfProductInputValue(minPurchasePrice);
    }
  };

  return (
    <>
      {!activePlan ? (
        <Grid.Col>
          <LoadingWrapper data-testid="finance-loader">
            <LoadingOverlay />
          </LoadingWrapper>
        </Grid.Col>
      ) : (
        <>
          <ContentWrapper>
            <Grid.Col lg={9} as={LeftColumnDiv}>
              <LeftColumnContent
                depositGuide={{
                  minDepositPercentage: activePlan?.minDepositPercentage || 0,
                  maxDepositPercentage: activePlan?.maxDepositPercentage || 0,
                }}
                purchasePrice={purchasePrice}
                setDepositAmount={setDepositAmount}
                content={content}
                depositValue={depositAmount}
                priceOfProduct={priceOfProductInputValue}
                onMonthlyPaymentChange={setSelectedPlanId}
                onPriceChange={handleOnPriceChange}
                onPriceBlur={handleOnPriceBlur}
                selectedPlanId={activePlan?.id}
                hasFixedAmount={Boolean(fixedAmount)}
                zeroPercentFinanceThirtySix={zeroPercentFinanceThirtySix}
                zeroPercentFinanceFortyEight={zeroPercentFinanceFortyEight}
                includeScrollDelay={includeScrollDelay}
                financePlans={financePlans}
              />
            </Grid.Col>
            <Grid.Col sm={0} md={0} lg={7} as={RightColumnDiv}>
              {isCalculating && (
                <CalculatingContainer data-testid="calculating-loader">
                  <LoadingOverlay />
                </CalculatingContainer>
              )}
              <RightColumnContent
                content={content}
                activePlan={activePlan}
                hasFixedAmount={Boolean(fixedAmount)}
                onAddToBasket={onAddToBasket}
                onProductDetailsClick={onProductDetailsClick}
                isBnpl={isBnpl}
                showAddToBasketCta={Boolean(showAddToBasketCta)}
                ctaText={ctaText}
                isPdpRedirectEnabled={isPdpRedirectEnabled}
              />
            </Grid.Col>
          </ContentWrapper>
          <PaymentDetails
            content={content}
            activePlan={activePlan}
            hasFixedAmount={Boolean(fixedAmount)}
            onAddToBasket={onAddToBasket}
            onProductDetailsClick={onProductDetailsClick}
            showAddToBasketCta={Boolean(showAddToBasketCta)}
            ctaText={ctaText}
            isPdpRedirectEnabled={isPdpRedirectEnabled}
            paymentType={isBnpl ? FinancePaymentTypes.BNPL : FinancePaymentTypes.Default}
          />
        </>
      )}
    </>
  );
};

export default FinanceCalculator;
