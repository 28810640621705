import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { useFsFlag } from "@flagship.io/react-sdk";
import { kebabCase } from "lodash-es";
import type { FontTheme, Weight } from "@boxt/design-system";
import { Button, H3, H4, H5, Paragraph, Spacing, ToolTip } from "@boxt/design-system";

import type { PageType } from "@Components/FinanceCalculator/types";
import { StyledHr } from "@Components/layout";
import ButterHTMLParsingContent from "@Components/pages/common/ButterHTMLParsingContent";
import type { ButterFinanceTextTabFields } from "@Components/pages/finance/types";
import { ReactComponent as InformationIcon } from "@Images/icons/information-icon.svg";

import { Container, CtaButtonText, FinanceItem, ParagraphTooltipContainer, TopSection } from "./styles";

type Props = {
  content: ButterFinanceTextTabFields;
  onAddToBasket?: () => void;
  onProductDetailsClick?: () => void;
  pageType?: PageType;
};

const componentMap = {
  h5: {
    align: "center",
    renderedAs: H5,
    theme: "slate" as FontTheme,
    weight: "regular" as Weight,
  },
};

const RightColumnContent = ({ content, onAddToBasket, onProductDetailsClick, pageType }: Props) => {
  const { t } = useTranslation("common");
  const isPdpRedirectEnabled = useFsFlag("pdp-is-mandatory-step-v2", false).getValue();
  const {
    text_tab_right_column_amount_label: amountLabel,
    text_tab_right_column_amount_value: amountValue,
    text_tab_right_column_amount_theme: amountTheme,
    text_tab_right_column_table_rows: tableRows,
    text_tab_cta_button_link: ctaButtonLink,
    text_tab_cta_button_text: ctaButtonText,
    text_tab_cta_button_theme: ctaButtonTheme,
    subscription_tab: isSubscriptionTab,
  } = content;

  const showButterCta = ctaButtonText && ctaButtonLink && pageType === "finance";

  return (
    <Container>
      <TopSection $hasTabRows={Number(tableRows?.length) > 0}>
        {amountLabel && amountValue && (
          <div>
            <ButterHTMLParsingContent
              addContainerSpacing={false}
              componentMap={componentMap}
              fields={{ content: amountLabel }}
              idAttribute="text-tab-amount-label"
            />
            <Spacing mb={4}>
              <H3 boxtTheme={amountTheme?.theme} align="center" weight="bold">
                {amountValue}
              </H3>
            </Spacing>
          </div>
        )}
        <Spacing mb={4}>
          {tableRows?.map(({ meta, name, value, tooltip, slug }) => (
            <div key={meta.id} data-testid={`text-tab-right-column-table-${kebabCase(name)}`}>
              <StyledHr data-testid="horizontal-rule" />
              <Spacing mv={3}>
                <FinanceItem>
                  <ParagraphTooltipContainer>
                    <Paragraph boxtTheme="coalDark">{`${name}:`}</Paragraph>
                    {tooltip && (
                      <ToolTip data-testid="tooltip" toolTipText={tooltip}>
                        <InformationIcon />
                      </ToolTip>
                    )}
                  </ParagraphTooltipContainer>
                  {slug === "table-row-monthly-payments-subscription-tab" && isSubscriptionTab ? (
                    <H4 boxtTheme="coalDark" weight="regular">
                      {amountValue}
                    </H4>
                  ) : (
                    <H4 boxtTheme="coalDark" weight="regular">
                      {value}
                    </H4>
                  )}
                </FinanceItem>
              </Spacing>
            </div>
          ))}
        </Spacing>
      </TopSection>

      {showButterCta && (
        <Link href={ctaButtonLink} passHref legacyBehavior>
          <Button type="submit" boxtTheme={ctaButtonTheme?.theme || "jade"} isFullWidth size="large">
            <CtaButtonText>{ctaButtonText}</CtaButtonText>
          </Button>
        </Link>
      )}

      {isPdpRedirectEnabled && pageType !== "finance" && (
        <Button
          boxtTheme="jade"
          isFullWidth
          size="large"
          onClick={pageType === "plp" ? onProductDetailsClick : onAddToBasket}
        >
          {pageType === "plp" ? t("Choose") : t("calculator.add-to-basket")}
        </Button>
      )}
      {!isPdpRedirectEnabled && pageType !== "finance" && !isSubscriptionTab && (
        <>
          <Button boxtTheme="jade" isFullWidth size="large" onClick={onAddToBasket}>
            {t("calculator.add-to-basket")}
          </Button>
          <Spacing mt={2} />
          <Button boxtTheme="coal" isFullWidth size="large" skin="secondary" onClick={onProductDetailsClick}>
            {t("calculator.view-product-detail")}
          </Button>
        </>
      )}
      {isSubscriptionTab && !showButterCta && (
        <Button boxtTheme="jade" isFullWidth size="large" onClick={onAddToBasket}>
          {t("calculator.explore-subscription")}
        </Button>
      )}
    </Container>
  );
};

export default RightColumnContent;
