export const sendToCs = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    window._uxa = window._uxa || [];
    if (Array.isArray(window._uxa)) {
      const hasBeenSent = window._uxa?.some((item) => item[0] === "trackDynamicVariable" && item[1].key === key);
      if (hasBeenSent) return;
    }
    window._uxa.push([
      "trackDynamicVariable",
      {
        key,
        value,
      },
    ]);
  }
};
