"use client";

import styled, { css } from "styled-components";
import { PaymentElement } from "@stripe/react-stripe-js";
import type { Step } from "@boxt/design-system";
import { breakpoint, includeShadows, includeSpacing, rem } from "@boxt/design-system";

export type LogoWrapperSpacing = {
  ml: {
    sm?: Step;
    md?: Step;
  };
  mb: {
    sm?: Step;
    md?: Step;
  };
};

export const StripePaymentElement = styled(PaymentElement)<{
  $isPaymentElementLoading: boolean;
  $isUpdatingElement: boolean;
}>`
  ${includeShadows(3)};
  border-radius: ${rem(4)};
  padding: 10px 12px;
  width: 100%;
  && {
    opacity: ${({ $isUpdatingElement }) => ($isUpdatingElement ? 0.2 : 1)};
  }
  visibility: ${({ $isPaymentElementLoading }) => ($isPaymentElementLoading ? "hidden" : "inherit")};
  ${includeSpacing({ mb: 3, mt: 1 })};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const Col = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LogoWrapper = styled.div<{ $spacing: LogoWrapperSpacing }>`
  display: flex;
  position: relative;
  align-items: center;
  gap: ${rem(4)};

  ${breakpoint("md")} {
    gap: ${rem(8)};
  }
  ${({ $spacing }) => css`
    ${includeSpacing($spacing)};
  `};
`;

export const IconWrapper = styled.div`
  height: ${rem(24)};
`;
