"use client";

import styled from "styled-components";
import { includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: ${rem(32)};

  ${includeSpacing({ mt: { sm: 4, lg: 0 } })};
`;

export const FinanceItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TopSection = styled.div<{ $hasTabRows: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $hasTabRows }) => ($hasTabRows ? `space-evenly` : `center`)};
  align-items: ${({ $hasTabRows }) => ($hasTabRows ? `stretch` : `center`)};
  height: 100%;
  width: 100%;
`;

export const ParagraphTooltipContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${rem(8)};
`;

export const CtaButtonText = styled.span`
  text-align: center;
`;
