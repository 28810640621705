import { useMemo } from "react";
import { last } from "lodash-es";

import type { Products } from "@Collections/products";
import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import useFetchFinancePlans from "@Hooks/useFetchFinancePlans";
import useHasFinance from "@Hooks/useHasFinance";

export type Props = {
  productType: Products | undefined;
  selectedPlanId?: string | null;
  deposit?: number;
  purchasePrice?: number;
  isBnpl?: boolean;
  isPayMonthly?: boolean;
  isBnplAvailable?: boolean;
  isBnplActive?: boolean;
  zeroPercentFinanceThirtySix?: boolean;
  zeroPercentFinanceFortyEight?: boolean;
};

type ReturnType = {
  activePlan: FinancePlan | null | undefined;
  isCalculating: boolean;
  isLoading: boolean;
  financePlans: FinancePlan[];
  hasError: Boolean;
  hasBnplPlans: boolean;
};

const useFinance = ({
  productType,
  deposit,
  selectedPlanId,
  purchasePrice,
  isBnpl = true,
  isPayMonthly = true,
  isBnplAvailable,
  isBnplActive,
  zeroPercentFinanceThirtySix = false,
  zeroPercentFinanceFortyEight = false,
}: Props): ReturnType => {
  const hasPurchasePrice = Number(purchasePrice) > 0;

  const { hasFinance } = useHasFinance({ productType });

  const {
    isLoading: isFetchingFinancePlans,
    isValidating: isCalculating,
    financePlans,
    hasError: hasFinancePlanError,
  } = useFetchFinancePlans({
    productType,
    deposit,
    purchasePrice,
    isBnpl,
    isPayMonthly,
  });

  const activePlan = useMemo(() => {
    if (!financePlans && !hasPurchasePrice) return null;

    if (selectedPlanId) {
      return financePlans.find((plan) => plan.id === selectedPlanId);
    }

    return last(financePlans);
  }, [financePlans, hasPurchasePrice, selectedPlanId]);

  const isLoading = hasFinance && isFetchingFinancePlans;

  const hasBnplPlans = financePlans?.some((plan) => plan?.deferredPeriod > 0);

  const isZeroPercentFinance = zeroPercentFinanceThirtySix || zeroPercentFinanceFortyEight;

  const filterPlansForZeroPercentFinance = useMemo(() => {
    if (!isZeroPercentFinance) {
      return financePlans.filter(({ apr }) => apr !== 0);
    }

    return financePlans.filter(({ apr, term }) => {
      if (apr !== 0) return true;
      if (term <= 36) {
        return zeroPercentFinanceThirtySix;
      }

      return zeroPercentFinanceFortyEight;
    });
  }, [isZeroPercentFinance, financePlans, zeroPercentFinanceFortyEight, zeroPercentFinanceThirtySix]);

  const availablePlans = useMemo(
    () =>
      isBnplActive && isBnplAvailable
        ? filterPlansForZeroPercentFinance.filter((plan) => plan.deferredPeriod > 0)
        : filterPlansForZeroPercentFinance.filter((plan) => plan.deferredPeriod === 0),
    [filterPlansForZeroPercentFinance, isBnplActive, isBnplAvailable],
  );

  return {
    isLoading,
    isCalculating,
    activePlan,
    financePlans: availablePlans,
    hasError: hasFinancePlanError,
    hasBnplPlans,
  };
};

export default useFinance;
