import type { StateValue } from "xstate";

import { REFERENCE_TYPE } from "@Constants/report-issue";
import { API_URLS } from "@Collections/routes";
import type { StringifiedBoolean } from "@Collections/types/common";
import { Event, Question } from "@StateMachines/Screener";
import { Action } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import NextApi from "src/dataAccess/local";

type CalloutScreenerAnswer = {
  orderType: string;
  id: string;
  refType: REFERENCE_TYPE;
  planName: string;
  isWorkmanshipGuaranteeActive: StringifiedBoolean;
  isBoxtLifeActive: StringifiedBoolean;
  isProtectPlan: StringifiedBoolean;
  isServicePlan: StringifiedBoolean;
  isWarrantyActive: StringifiedBoolean;
};

export const screenerErrorKey = "screener-error";

export const answerQuestion = async (url, question: StateValue, answer: string | null, event?: Event) => {
  const { data } = await NextApi.post(url, {
    action: Action.Answer,
    answer,
    event,
    question,
  });

  return data;
};

export const undoAnswer = async (url) => {
  const { data } = await NextApi.post(url, {
    action: Action.Back,
  });

  return data;
};

export const initScreenerWithPostcode = (variant: ScreenerVariant, answer: string | null) => {
  localStorage.removeItem(screenerErrorKey);
  return NextApi.post(`${API_URLS.SCREENING}?variant=${variant}`, {
    action: Action.Init,
    question: Question.Postcode,
    answer,
    event: Event.Answer,
  });
};

export const updatePostcode = (answer: string, variant: ScreenerVariant) => {
  return NextApi.put(`${API_URLS.SCREENING}?variant=${variant}`, {
    question: Question.Postcode,
    answer,
    event: Event.Update,
  });
};

export const initCalloutScreener = (answer: CalloutScreenerAnswer) => {
  localStorage.removeItem(screenerErrorKey);

  const variant =
    answer.refType === REFERENCE_TYPE.INSTALLATION
      ? ScreenerVariant.CalloutInstallation
      : ScreenerVariant.CalloutSubscription;

  return NextApi.post(`${API_URLS.SCREENING}?variant=${variant}`, {
    action: Action.Init,
    question: Question.OrderDetails,
    event: Event.Answer,
    answer,
  });
};
