import useTranslation from "next-translate/useTranslation";
import { H5, Paragraph, Spacing } from "@boxt/design-system";

import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";

import MonthlyPayment from "./MonthlyPayment";
import { Container, InterestGroupWrapper, LabelContainer, LeftLine, RightLine, StyledLabel } from "./styles";

export type Props = {
  onMonthlyPaymentChange: (id: string) => void;
  selectedPlanId: string | undefined;
  zeroPercentFinanceThirtySix?: boolean;
  zeroPercentFinanceFortyEight?: boolean;
  financePlans: FinancePlan[] | undefined;
};

const checkPlan = (
  zeroPercentFinanceThirtySix: boolean,
  zeroPercentFinanceFortyEight: boolean,
  plan: FinancePlan,
): boolean => {
  const { term } = plan;

  if (term > 36) {
    return zeroPercentFinanceFortyEight;
  }
  return zeroPercentFinanceThirtySix;
};

const MonthlyPayments = ({
  onMonthlyPaymentChange,
  selectedPlanId,
  zeroPercentFinanceThirtySix,
  zeroPercentFinanceFortyEight,
  financePlans,
}: Props) => {
  const { t } = useTranslation("common");

  const interestRateGroups: Record<string, FinancePlan[]> =
    financePlans?.reduce((acc, plan) => {
      const { apr: interestRatePercentage } = plan;
      const isZeroInterest = interestRatePercentage === 0;

      if (!acc[interestRatePercentage]) {
        acc[interestRatePercentage] = [];
      }
      if (isZeroInterest) {
        checkPlan(Boolean(zeroPercentFinanceThirtySix), Boolean(zeroPercentFinanceFortyEight), plan) &&
          acc[interestRatePercentage].push(plan);
      } else {
        acc[interestRatePercentage].push(plan);
      }

      return acc;
    }, {}) || {};

  return (
    <Spacing mb={4}>
      <H5 bottom={3}>{t("calculator.number-of-monthly-payments")}</H5>
      <Container>
        {Object.entries(interestRateGroups)
          ?.filter(([, plans]) => plans.length)
          ?.map(([interestRate, plans]: [string, FinancePlan[]]) => {
            const isZeroInterest = interestRate === "0";
            return (
              <InterestGroupWrapper key={interestRate}>
                <Container>
                  {plans.map((plan) => (
                    <MonthlyPayment
                      onChange={onMonthlyPaymentChange}
                      key={plan.id}
                      name="finance-plan"
                      plan={plan}
                      selectedPlanId={selectedPlanId}
                    />
                  ))}
                </Container>
                <LabelContainer mt={1} mb={{ sm: isZeroInterest ? 2 : 0, md: 0 }}>
                  <LeftLine />
                  <StyledLabel $isZero={isZeroInterest}>
                    <Spacing mh={1}>
                      <Paragraph
                        weight={isZeroInterest ? "bold" : "regular"}
                        size="small"
                        boxtTheme={isZeroInterest ? "coalDark" : "slateLight"}
                      >
                        {t("calculator.apr-rate", { rate: interestRate })}
                      </Paragraph>
                    </Spacing>
                  </StyledLabel>
                  <RightLine />
                </LabelContainer>
              </InterestGroupWrapper>
            );
          })}
      </Container>
    </Spacing>
  );
};

export default MonthlyPayments;
